export const isProdEnv = () => {
    const hostname = window.location.hostname;
    if (hostname === 'gigsremote.com') {
        return true;
    } else if (hostname === 'localhost' || hostname === '127.0.0.1' || hostname === 'demo.gigsremote.com') {
        return false;
    } else {
        throw new Error('Environment not supported');
    }
}
export const gigsRemoteUrl = isProdEnv() ? 'https://my.gigsremote.com/' : 'https://my.demo.gigsremote.com/'