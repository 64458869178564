import {DOM} from './dom';

export namespace ScrollListener {
  let idx = 0;
  const callbacks: ((elem: HTMLElement) => void)[] = [];
  const SCROLL_IDX_ATTR = 'data-scrl-idx';
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.target.hasAttribute(SCROLL_IDX_ATTR)) {
        const elem = entry.target as HTMLElement;
        const idx = parseInt(elem.getAttribute(SCROLL_IDX_ATTR) as string);
        callbacks[idx](elem);
      }
    });
  });

  export function watchFor(selector: string, callback: (elem: HTMLElement) => void): void {
    const elemsIdx = idx++;
    callbacks.push(callback);
    DOM.bySelector(selector, elem => {
      elem.setAttribute(SCROLL_IDX_ATTR, elemsIdx + '');
      observer.observe(elem);
    });
  }
}