export namespace DOM {
  export function byId<T extends HTMLElement>(id: string): T {
    return document.getElementById(id) as T;
  }

  export function byClass(className: string): HTMLCollectionOf<Element> {
    return document.getElementsByClassName(className);
  }

  export function bySelector(selector: string, callback: (elem: HTMLElement) => void): void {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector);
    elements.forEach(callback);
  }

  export function onIdClick<T extends HTMLElement>(id: string, callback: (ev: MouseEvent, elem: T) => void): void {
    const element = byId<T>(id);
    element.addEventListener('click', ev => { callback(ev, element); });
  }

  export function onMatchClick(selector: string, callback: (ev: MouseEvent, elem: HTMLElement) => void): void {
    bySelector(selector, elem => {
      elem.addEventListener('click', ev => { callback(ev, elem); });
    });
  }

  export function removeClass(selector: string, className: string): void {
    bySelector(selector, elem => { elem.classList.remove(className); });
  }
  
  export function redirectTo(href: string): void {
    const a = document.createElement('a');
    a.href = href;
    a.click();
  }

  export function onReady(callback: Function): void {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => { callback(); });
    } else {
      callback();
    }
  }
}