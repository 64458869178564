import { DOM } from '../core/dom';
import { gigsRemoteUrl } from '../core/environment-utils';

export namespace Header {
  export function onload(): void {
    DOM.bySelector('#login-anchor', (loginAnchor) => {
      const loginBtn = loginAnchor as HTMLAnchorElement;
      loginBtn.href = gigsRemoteUrl;
    });

    const header = DOM.byId('top-header');
    const mobileMenu = DOM.byId('mobile-menu');
    mobileMenu.addEventListener('click', () => {
      if (header.classList.contains('expanded')) {
        header.classList.remove('expanded');
        document.body.classList.remove('disable-scroll');
      } else {
        header.classList.add('expanded');
        document.body.classList.add('disable-scroll');
      }
    });
  }
}