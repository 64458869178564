import {DOM} from '../core/dom';
import {ScrollListener} from '../core/scroll-listener';
import {Header} from '../partials/header';

DOM.onReady(() => {
  Header.onload();
  ScrollListener.watchFor('.gigs-btn, #find-remote > .block, .highlight-freelancer, .highlight-company, .highlight-neutral, .home-related > .button-group > a', elem => {
    elem.classList.add('run-animation');
    setTimeout(() => { elem.classList.remove('run-animation'); }, 550);
  })
});